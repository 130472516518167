.modalContainer {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContainer .modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid lightgray;
  border-top: 10px solid #e6af48;
  width: 60%;
}

.modalContainer .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.modalContainer .close:hover,
.modalContainer .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
